export default {
    chambersLocations: {
        confirm: "Bestätigen",
        tableHeaders: {
            chamber: "Zugehörige Kammer",
            location: "Online-Ort-Zuordnung",
            onlineKeyword: "Online-Stichwörter"
        },
        editPopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            chamber: "Kammer",
            onlineKeyword: "Online-Schlüsselwort",
            createChamberLocation: "Neue Kammer hinzufügen",
            editChamberLocation: "Kammer Standort bearbeiten",
            deleteLocation: "Verknüpfung löschen",
            deleteLocationMessage:
                "Sind sie sicher, dass Sie die Verknüpfung aufheben möchten?",
            deleteLocationTitle: "Bestätigen Sie die Aufhebung",
            createOnlineKeyword:
                "Fügen Sie ein neues Online-Schlüsselwort hinzu",
            saveChanges: "Änderungen speichern",
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?"
        },
        deletePopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            deleteOnlineKeyword: "Bestätigen Sie den Löschvorgang",
            deleteOnlineKeywordMessage:
                "Sind Sie sicher, dass Sie es löschen möchten?",
            deleteOnlineKeywordTitle: "Bestätigen Sie die Aufhebung"
        },
        errorDuplicatedKeyword: {
            subtitle:
                "Es scheint, dass das Stichwort, das Sie zu erstellen versuchen, bereits existiert. Bitte versuchen Sie, ein neues anzulegen.",
            title: "Vorhandenes Stichwort"
        },
        success: {
            create: "Erfolgreich erstellt",
            delete: "Erfolgreich gelöscht",
            update: "Erfolgreich aktualisiert"
        },
        error: {
            subtitle:
                "Es scheint ein technischer Fehler bei Ihrer Eingabe vorzuliegen. Bitte versuchen Sie es erneut.",
            title: "Ups, da scheint ein Fehler vorzuliegen!"
        },
        errors: {
            errorExists:
                "Das Schlüsselwort existiert bereits! Versuchen Sie es mit einem anderen",
            errorDelete:
                "Beim Löschen Ihrer Zuordnung scheint ein technisches Problem oder ein Fehler aufgetreten zu sein. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuche es erneut.",
            genericErrorCreate:
                "Es scheint ein technisches Problem oder ein Fehler bei Ihrer Kartierungserstellung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorUpdate:
                "Es scheint ein technisches Problem oder ein Fehler bei der Aktualisierung Ihres Verknüpfung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericTitle: "Ups, es scheint ein Fehler vorzuliegen!"
        },

        tabs: {
            legalEntities: "Zuordnung",
            onlineKeywords: "Online Stichwörter"
        },
        tryAgain: "Erneut versuchen"
    },
    chatbot: {
        assistant: "Assistent",
        feedbackTitle:
            "Hilf mit deinem feedback die beratung noch besser zu machen.",
        inputHint: "Eine nachricht eingeben...",
        noSeminars: "Keine Seminare verfügbar",
        onRequest: "Auf Anfrage",
        startConversationButton: "Gespräch einleiten",
        talkingWithHumanNotification: "Du sprichst jetzt mit einem Menschen."
    },
    chatInteraction: {
        archivedTalks: "Archivierte Gespräche",
        callRequest: "Gesprächsanfrage",
        errors: {
            alreadyProcessed: "Anfrage wird schon bearbeitet",
            alreadyProcessedDescription:
                "Diese Anfrage wird schon von einem anderen Agenten bearbeitet. Bitte laden Sie die Seite neu und wählen Sie eine andere Anfrage."
        },
        ongoingConversations: "Gespräche",
        reloadPage: "Seite neu laden"
    },
    contractStatus: {
        all: "Alle",
        contractStatus: "Bildungsträger Status AV-Vertrag",
        signed: "Nur mit AV-Vertrag",
        notSigned: "Nur ohne AV-Vertrag"
    },
    consents: {
        dataPolicy: "Datenschutzerklärung",
        licenseAgreement: "Lizenzbedingungen",
        terms: "AGB",
        title: "AGB, Datenschutzerklärung und Lizenzbedingungen"
    },
    dashboard: {
        availableMenus: "Hier finden Sie folgende Funktionen",
        chatbotData: "Chatbot Trend-Daten",
        events: "Veranstaltungen",
        generalData: "Allgemeine Daten",
        keywordsFromChatbot: "Stichworte aus dem Bot - letzte 30 Tage",
        locations: "Standorte/Städte",
        nationwideCert: "Nationaler ZERT.",
        offers: "Angebote",
        regionalCert: "Regionaler ZERT.",
        status: "Stand",
        topLocationsFromChatbot:
            "Top 10 Standorte aus dem Bot - letzte 30 Tage",
        topOffersBySuppliers: "Top 10 Angebote nach Anzahl Bildungsträger",
        topOffersFromChatbot: "Top 10 Angebote aus dem Bot - letzte 30 Tage",
        topStatistics: "Dashboard — Top-Statistiken",
        topSuppliersByOffers: "Top 10 Bildungsträger nach Anzahl Angeboten",
        totalLocations: "Total Standorte",
        totalEvents: "Total Veranstaltungen aktuell",
        totalEventsAcummulated:
            "Total Veranstaltungen kumuliert (seit Juli 2023)",
        totalOffers: "Total Angebote",
        userManualReference:
            "Und oben rechts gibt es ein Benutzerhandbuch mit weiteren Informationen",
        welcome: "Herzlich Willkommen im ISA-Backoffice"
    },
    entitiesMapping: {
        confirm: "Bestätigen",
        contractStatus: {
            signed: "Unterzeichnet",
            notSigned: "Nicht unterzeichnet"
        },
        delete: "Löschen",
        deleteQuestionConfirmation:
            "Sind Sie sicher, dass Sie diese Verknüpfung löschen möchten?",
        editPopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            delete: "Löschen",
            contractStatus: {
                contractStatus: "Status AV-Vertrag ändern:",
                signed: "Unterzeichnet",
                notSigned: "Nicht unterzeichnet"
            },
            createMapping: "Verknüpfung erstellen",
            deleteMapping: "Verknüpfung löschen",
            deleteMappingMessage:
                "Sind sie sicher, dass Sie die Verknüpfung aufheben möchten?",
            deleteMappingTitle: "Bestätigen Sie die Aufhebung",
            editMapping: "Verknüpfung bearbeiten",
            deleteEvents: "Veranstaltungen löschen",
            deleteEventsTitle: "Veranstaltungen löschen",
            deleteEventsMessage:
                "Bitte bestätigen Sie, dass Sie ALLE Veranstaltungen, die diesem Bildungsträger zugeordnet sind, unwiderruflich aus der Datenbank entfernen möchten.",
            legalEntity: "Zugehörige Kammer",
            saveChanges: "Änderungen speichern",
            suppliers: "Bildungs-Träger",
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            taxType: {
                taxType: "Enthält Mehrwertsteuer (MwSt.)",
                included: "MwSt. enthalten",
                excluded: "MwSt. befreit"
            },
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?"
        },
        errors: {
            duplicatedMappingError:
                "Ups, es scheint, dass die Verknüpfung bereits existiert. Bitte überprüfen Sie die Bildungsträge und die zugehörigen Kammern und versuchen sie es erneut.",
            duplicatedMappingTitle: "Doppelte Verknüpfung!",
            errorDelete:
                "Es scheint ein technisches Problem oder ein Fehler bei der Löschung Ihrer Kartierung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorCreate:
                "Es scheint ein technisches Problem oder ein Fehler bei Ihrer Kartierungserstellung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorUpdate:
                "Es scheint ein technisches Problem oder ein Fehler bei der Aktualisierung Ihres Verknüpfung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericTitle: "Ups, es scheint ein Fehler vorzuliegen!",
            noSeminarsFound: "Diesem Anbieter sind keine Seminare zugeordnet."
        },
        success: {
            create: "Verknüpfung erfolgreich erstellt",
            delete: "Verknüpfung erfolgreich gelöscht",
            update: "Verknüpfung erfolgreich aktualisiert",
            deleteSemianrs: "Ereignisse wurden erfolgreich gelöscht."
        },
        tableHeaders: {
            legalEntity: "Zugehörige Kammer",
            suppliers: "Bildungs-Träger",
            contractStatus: "Status Av-Vertrag"
        },
        tabs: {
            legalEntities: "Kammern",
            suppliers: "Bildungsträger"
        },
        tryAgain: "Erneut versuchen"
    },
    coopPartnersMapping: {
        confirm: "Bestätigen",
        contractStatus: {
            signed: "Unterzeichnet",
            notSigned: "Nicht unterzeichnet"
        },
        delete: "Löschen",
        deleteQuestionConfirmation:
            "Sind Sie sicher, dass Sie diese Verknüpfung löschen möchten?",
        editPopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            delete: "Löschen",
            contractStatus: {
                contractStatus: "Status AV-Vertrag ändern:",
                signed: "Unterzeichnet",
                notSigned: "Nicht unterzeichnet"
            },
            createMapping: "Kooperationspartner erstellen",
            deleteMapping: "Verknüpfung aufheben",
            deleteMappingMessage:
                "Sind sie sicher, dass Sie die Verknüpfung aufheben möchten?",
            deleteMappingTitle: "Bestätigen Sie die Aufhebung",
            editMapping: "Verknüpfung bearbeiten",
            deleteCoopPartner: "Löschen",
            deleteCoopPartnerTitle: "Kooperationspartner löschen",
            deleteCoopPartnerMessage:
                "Bitte bestätigen Sie, dass Sie den Kooperationspartner unwiderruflich aus der Datenbank entfernen möchten. Ausgestellte Badges bleiben davon unberührt.",
            duplicatedCoopPartners:
                "Dieser Kooperationspartner existiert bereits. Versuchen Sie es mit einem anderen.",
            legalEntity: "Zugehörige Kammer",
            saveChanges: "Änderungen speichern",
            coopPartners: "Kooperationspartner",
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            receivableNumber: "Debitorennummer",
            payableNumber: "Kreditorennummer",
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?"
        },
        errors: {
            duplicatedMappingError:
                "Ups, es scheint, dass die Verknüpfung bereits existiert. Bitte überprüfen Sie die Bildungsträge und die zugehörigen Kammern und versuchen sie es erneut.",
            duplicatedMappingTitle: "Doppelte Verknüpfung!",
            errorDelete:
                "Es scheint ein technisches Problem oder ein Fehler bei der Löschung Ihrer Kartierung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorCreate:
                "Es scheint ein technisches Problem oder ein Fehler bei Ihrer Kartierungserstellung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorUpdate:
                "Es scheint ein technisches Problem oder ein Fehler bei der Aktualisierung Ihres Verknüpfung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericTitle: "Ups, es scheint ein Fehler vorzuliegen!"
        },
        success: {
            create: "Kooperationspartner wurde erfolgreich angelegt.",
            delete: "Verknüpfung erfolgreich gelöscht",
            update: "Verknüpfung erfolgreich aktualisiert",
            deleteCooppartner: "Kooperationspartner wurde erfolgreich gelöscht."
        },
        tableHeaders: {
            legalEntity: "Zugehörige Kammer",
            coopPartners: "Kooperationspartner"
        },
        tabs: {
            legalEntities: "Kammern",
            coopPartners: "Kooperationspartner"
        },
        tryAgain: "Erneut versuchen"
    },
    entitiesUploads: {
        filters: {
            nameAsc: "Name A - Z",
            noUploads: "Nicht vorhanden",
            oldUploads: "Alte Uploads",
            regularUploads: "Eingehend"
        },
        tableHeaders: {
            lastUpload: "Letzter Upload",
            legalEntity: "Name Kammer",
            status: "Status",
            suppliers: "Ihk-Bildungszentrum"
        }
    },
    errors: {
        "auth/invalid-email": "Ungültige E-Mail",
        "auth/user-disabled": "Dieses Benutzerkonto scheint inaktiv zu sein.",
        "auth/user-not-found":
            "Benutzer nicht gefunden, bitte versuchen Sie es erneut.",
        "auth/wrong-password": "Falsches Passwort",
        default: "Ein Fehler ist aufgetreten"
    },
    event: {
        associatedSeminar: "Zugehöriges Seminar",
        bookedPlaces: "Gebuchte Plätze",
        city: "Stadt",
        contact: "Kontakt",
        countryCode: "Vorwahl",
        date: "Datum",
        delete: "Löschen",
        deleteEventMessage:
            "Bitte bestätigen Sie die Löschung dieses Termins inklusive aller Buchungen.",
        deleteEventTitle: "Ereignis löschen",
        duration: "Dauer",
        email: "E-Mail",
        endDate: "EndDatum",
        enrolmentDeadline: "Anmeldeschluss",
        eventType: "Ereignistyp",
        externalId: "Externe ID",
        externalLink: "Externer Info-Link",
        enrolmentLink: "Buchungslink",
        from: "vom",
        genericError: {
            title: "Hoppla, da scheint ein Fehler vorzuliegen!",
            subtitle:
                "Es scheint, dass ein technisches Problem aufgetreten ist und Ihre Anfrage nicht durchgekommen ist."
        },
        ihk: "IHK",
        ihkEntity: "IHK-Entität",
        ihkLink: "IHK-Link",
        ihkName: "IHK-Name",
        instructor: "Dozent",
        lastUpdated: "Zuletzt aktualisiert am",
        latitude: "Breitengrad",
        location: "Standort",
        longitude: "Länge",
        module: "Modul",
        moduleNumber: "Modul Nr.",
        modules: "Module",
        name: "Name",
        onRequest: "Auf Anfrage",
        pictureLink: "Bild-Link",
        postalCode: "Postleitzahl",
        price: "Preis",
        source: "Quelle",
        sourceType: {
            chamaeleon: "Chamaeleon",
            gfi_upload: "GFI Upload",
            manual_creation: "Manuell angelegt",
            materna: "Materna",
            scandio: "Scandio",
            xml_upload: "XML Upload",
            ftp: "FTP Upload",
            antrago: "Antrago",
            dezent: "Dezent",
            sweap: "Sweap",
            softart: "Softart"
        },
        startDate: "StartDatum",
        telephone: "Telefon",
        telephoneNumber: "Rufnummer",
        timestamp: "Zeitstempel",
        to: "bis",
        type: {
            blended: "Blended",
            presence: "Präsenz",
            online: "Online"
        },
        unit: {
            hours: "Stunden",
            stunden: "Stunden",
            weeks: "Wochen",
            wochen: "Wochen",
            days: "Tage",
            tage: "Tage",
            day: "Tag",
            tag: "Tag",
            months: "Monate",
            monate: "Monate"
        },
        unmerge: {
            success:
                "Die Verknüpfung wurde aufgehoben. Die Veranstaltungen sind im Menü Angebotszuordnung unter den zu klärenden Angeboten.",
            subtitle:
                "Wenn Sie fortfahren, wird die Verknüpfung von allen unten gezeigten Veranstaltungen mit diesem Angebot aufgehoben. Die Veranstaltungen werden dann nicht mehr synchronisiert.",
            title: "Folgende Veranstaltungen sind mit diesem Angebot verknüpft",
            unlink: "Verknüpfung aufheben"
        }
    },
    events: {
        editPopup: {
            addContact: "Kontakt Hinzufügen",
            addInstructor: "Dozent Hinzufügen",
            addModule: "Module Hinzufügen",
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            createEvent: "Ereignis erstellen",
            createNew: "Neu erstellen",
            durationUnit: "Einheit der Dauer",
            durationValue: "Wert der Dauer",
            editEvent: "Ereignis bearbeiten",
            genericError: {
                title: "Hoppla, da scheint ein Fehler vorzuliegen!",
                subtitle:
                    "Es scheint, dass ein technisches Problem aufgetreten ist und Ihre Anfrage nicht durchgekommen ist."
            },
            postalCode: "PLZ",
            saveChanges: "Änderungen speichern",
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?",
            tryAgain: "Erneut versuchen"
        },
        tableHeaders: {
            bookedPlaces: "Gebuchte Plätze",
            instructor: "Dozent",
            location: "Ort",
            price: "Preis",
            startDate: "StartDatum",
            type: "Angebotstyp"
        }
    },
    getStarted: {
        backToLogin: "Zurück zum Login",
        confirmNewPassword: "Neues Passwort bestätigen",
        consent: {
            consents: {
                1: {
                    text1: "Ich habe die",
                    text2: "und",
                    text3: "gelesen und bin mit deren Geltung einverstanden."
                },
                2: {
                    text1: "Ich habe die",
                    text2: "gelesen und bin damit einverstanden."
                }
            }
        },
        createNewPassword: "Erstellen Sie ein neues Passwort.",
        endSetup: "Setup beenden",
        errorMessage: {
            passwordsDoNotMatch:
                "Es scheint, dass die Passwörter nicht übereinstimmen. Bitte versuchen Sie es erneut.",
            weakNewPassword:
                "Oops sorry, dein Passwort ist zu schwach. Versuchen Sie, einen Großbuchstaben und / oder Zahlen einzufügen."
        },
        getStarted: "Erste Schritte",
        lastStep:
            "Nur noch ein weiterer Schritt, um die Einrichtung Ihres Kontos abzuschließen.",
        login: "Login",
        newPassword: "Neues Passwort",
        passwordInformation:
            "Denken Sie daran, dass Sie den in Ihrer E-Mail angegebenen Passwort-Code verwenden sollten.",
        proceed: "Weitermachen",
        setupAccount: "Bitte initiieren Sie die Einrichtung Ihres Kontos.",
        userHaveAccount: "Sie haben bereits ein Konto?",
        termsAndConditions: "Aktualisierte Geschäftsbedingungen",
        acceptTermsAndConditions:
            "Aktualisierung unserer Allgemeinen Geschäftsbedingungen Stand Juni 2023"
    },
    invoicing: {
        archive: "Archiv",
        archiveError: {
            description:
                "Es scheint ein technisches Problem oder ein Fehler bei der Archivierung dieser Veranstaltung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            title: "Ups, da scheint ein Fehler vorzuliegen!"
        },
        archiveEvent: "Veranstaltung archivieren",
        archiveEventConfirmation:
            "Sind Sie sicher, dass Sie diese Veranstaltung archivieren möchten?",
        bookingDate: "Buchungsdatum",
        bookingRequestDate: "Datum der ISA Anfrage",
        close: "Schließen",
        confirm: "Bestätigen",
        confirmAndExport: "Bestätigen und Exportieren",
        exportQuestion: "Möchten Sie diese Buchungen exportieren?",
        filters: {
            archived: "Archiv",
            all: "Alle",
            future: "Angebot noch nicht gestartet",
            past: "Angebot gestartet"
        },
        genericError: {
            description:
                "Es scheint ein technischer Fehler bei Ihrer Eingabe vorzuliegen. Bitte versuchen Sie es erneut.",
            title: "Ups, da scheint ein Fehler vorzuliegen!"
        },
        moreInfo: "Mehr Informationen",
        open: "Öffnen",
        participant: "Teilnehmer",
        postalCode: "Postleitzahl",
        status: "Rechnungsstatus",
        success: {
            text1: "Die Veranstaltung zum",
            text2: ", wurde erfolgreich archiviert."
        },
        successExport: "Ausgewählte Buchungen wurden erfolgreich exportiert!",
        tableHeaders: {
            bookingsNumber: "Anzahl der Buchungen",
            classification: "Klassifizierung",
            offer: "Angebotstitel",
            registration: "Anmeldung",
            startDate: "Startdatum",
            supplier: "Bildungsträger"
        },
        tryAgain: "Erneut versuchen"
    },
    labels: {
        close: "Schließen",
        confirm: "Bestätigen",
        continue: "Weiter",
        createNew: "Neu erstellen",
        delete: "Löschen",
        details: "Details",
        edit: "Bearbeiten",
        filter: "Filter",
        noOptions: "Keine Optionen",
        open: "Öffnen",
        resetAll: "Alle Zurücksetzen",
        search: "Suche..."
    },
    login: {
        backoffice: "Backoffice",
        contactAdmin: "Kontakt-Administrator",
        email: "E-Mail",
        forgotPassword: "Passwort vergessen?",
        login: "Einloggen",
        password: "Passwort",
        pleaseLogin: "Bitte logge dich in dein Konto ein.",
        contactInfo:
            "Du hast noch kein Konto? Melde dich gerne bei uns via Mail",
        contactInfo2: "und wir erstellen eins für dich.",
        or: "oder per Telefon",
        welcomeTo: "Willkommen im"
    },
    marketingMaterials: {
        confirm: "Bestätigen",
        delete: "Löschen",
        link: "Link öffen",
        deleteQuestionConfirmation:
            "Sind Sie sicher, dass Sie diese Marketing material löschen möchten?",
        errors: {
            errorDelete:
                "Es scheint ein technisches Problem oder ein Fehler bei der Löschung Ihrer Marketing Material vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericErrorCreate:
                "Es scheint ein technisches Problem oder ein Fehler bei Ihrer Kartierungserstellung vorzuliegen. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte versuchen Sie es erneut.",
            genericTitle: "Ups, es scheint ein Fehler vorzuliegen!"
        },
        editPopup: {
            cancel: "Abbrechen",
            createMarketingMaterial: "Neuen Eintrag bearbeiten",
            deleteMarketingMaterial: "Eintrag löschen",
            deleteMarketingMaterialMessage:
                "Sind Sie sicher, dass Sie den Eintrag löschen möchten?",
            deleteMaterialTitle: "Bestätigen Sie die Löschung",
            editMarketingMaterial: "Eintrag bearbeiten",
            materialNameLabel: "Link Name",
            materialUrlLabel: "Link",
            saveChanges: "Änderungen speichern",
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?"
        },
        success: {
            create: "Link erfolgreich erstellt",
            delete: "Link erfolgreich gelöscht",
            update: "Link erfolgreich aktualisiert"
        },
        tryAgain: "Erneut versuchen",
        multiFileUpload: {
            label: "Marketing-Link-Bild",
            text: "Drag & Drop oder hier klicken, um eine Bilddatei hochzuladen",
            note: "Das Bild sollte idealerweise eine Größe von 280x280 Pixeln (Seitenverhältnis 1:1) besitzen."
        }
    },
    navigationMenu: {
        chatInteraction: "Chat-Verwaltung",
        chambersLocations: "Online-Ort-Zuordnung",
        dashboard: "Startseite",
        entitiesMapping: "Trainingspartner-Verwaltung",
        entitiesMappingManagement: "Verknüpfungsverwaltung",
        coopPartnersMapping: "Kooperationspartner-Verwaltung",
        entitiesUploads: "Angeschlossene Kammern",
        invoicing: "Abrechnung",
        marketingMaterials: "Marketing Material",
        offersClassification: "Angebotszuordnung",
        offersFileUpload: "XML datei-upload",
        offersKeywordsTagging: "Stichwortzuordnung",
        offerManagement: "Angebotsverwaltung",
        offersOverview: "Angebotsübersicht",
        badges: "Badges",
        userManagement: "Benutzerverwaltung",
        unmappedEntities: "Ohne Verknüpfung",
        userManual: "Nutzer-Handbuch"
    },
    notifications: {
        notifications: "Notifications",
        showAll: "Alle anzeigen"
    },
    offerDetails: {
        delete: "Löschen",
        deleteOfferMessage:
            "Bitte bestätigen Sie die Löschung dieses Angebots inklusive aller ggf. in der Vergangenheit angefallenen Buchungen.",
        deleteOfferTitle: "Angebot löschen",
        details: {
            additionalLinks: "Interessante Links",
            chatbots: "Chatbots",
            classification: "Klassifizierung",
            displayTitle: "Anzeige-Titel",
            approvedStatus: "Dieses Angebot ist aktiv",
            draftStatus: "Dieses angebot ist noch ein entwurf",
            flyers: "Flyers",
            images: "Bild",
            keywords: "Stichwörter",
            longDescription: "Long-Description",
            marketingMaterialsLink: "Marketingmaterialien Link",
            pdfLink: "Pdf-link",
            podcasts: "Podcasts",
            presentations: "Presentations",
            shortDescription: "Small-Description",
            url: "URL",
            cmsUrl: "CMS URL",
            videos: "Videos",
            videoProccesing: "Video wird verarbeitet…",
            refreshVideo: "Aktualisieren",
            voiceDescription: "Kurzbeschreibung für Smart Speaker App"
        },
        tabs: {
            details: "Allgemeine Informationen",
            events: "Veranstaltungen"
        }
    },
    offers: {
        classification: "Klassifizierung",
        classifications: {
            all: "Alle",
            nationwide: "Nationaler Zert.",
            regional: "Regionaler Zert."
        },
        editPopup: {
            addAdditionalLinks: "Link Hinzufügen",
            addChatbot: "Chatbot Hinzufügen",
            addFlyer: "Flyer Hinzufügen",
            addImage: "Bild Hinzufügen",
            additionalLinks: "Interessante Links",
            addMarketingMaterials: "Marketingmaterialien-Link Hinzufügen",
            addVideo: "Video Hinzufügen",
            addPresentation: "Präsentation Hinzufügen",
            addPodcast: "Podcast Hinzufügen",
            browseFile: "Datei durchsuchen",
            cancel: "Abbrechen",
            chatbot: "Chatbot",
            chatbotImage: "Chatbot-Bild",
            classification: {
                classification: "Klassifizieren",
                nationwide: {
                    label: "Nationaler Zertifikatslehrgang",
                    tooltip:
                        "Bei der Klassifizierung als Nationaler Zertifikatslehrgang wird das Angebot sofort freigegeben."
                },
                regional: {
                    label: "Regionaler Zertifikatslehrgang",
                    tooltip:
                        "Bei der Klassifizierung als Regionaler Zertifikatslehrgang wird das Angebot sofort freigegeben."
                }
            },
            status: {
                label: "Angebotsstatus",
                approved: {
                    label: "Aktiv"
                },
                draft: {
                    label: "Inaktiv",
                    disabledTooltip:
                        "Dieses Angebot hat Veranstaltungen, die bereits gestartet sind oder gebucht wurden. Daher kann der Status nicht mehr verändert werden."
                }
            },
            confirm: "Bestätigen",
            createOffer: "Angebot erstellen",
            description: "Beschreibung",
            displayTitle: "Anzeige-Titel",
            draftOffer: "Als entwurf speichern",
            editOffer: "Angebot bearbeiten",
            flyer: "Flyer",
            flyerFile: "Flyer-Präsentation",
            flyerImage: "Flyer-Bild",
            genericError: {
                title: "Hoppla, da scheint ein Fehler vorzuliegen!",
                subtitle:
                    "Es scheint, dass ein technisches Problem aufgetreten ist und Ihre Anfrage nicht durchgekommen ist."
            },
            image: "Bild",
            imageFile: "Bild",
            keywords: "Stichwörter",
            marketingMaterials: "Marketingmaterialien",
            marketingMaterialsLink: "Marketingmaterialien-Link",
            marketingMaterialsLinkPlaceholder:
                "Marketingmaterialien-URL-Adresse",
            name: "Name",
            podcast: "Podcast",
            podcastFile: "Podcast-Audiodatei",
            presentation: "Präsentation",
            presentationFile: "Präsentation",
            presentationImage: "Präsentation-Bild",
            publish: "Veröffentlichen",
            requiredFlyerFileImage:
                "Für Flyer ist mindestens ein Bild oder eine Präsentationsdatei erforderlich",
            requiredPresentationFileImage:
                "Für Präsentationen ist mindestens ein Bild oder eine Präsentationsdatei erforderlich",
            saveChanges: "Änderungen speichern",
            shortDescription: "Kurzbeschreibung",
            subtitle: "Untertitel",
            title: "Titel",
            tryAgain: "Erneut versuchen",
            upload: "Hochladen",
            url: "URL",
            cmsUrl: "CMS URL",
            urlError: {
                title: "Ungültige URL",
                subtitle:
                    "Es scheint, dass diese URL bereits existiert. Bitte geben Sie eine neue URL an und versuchen Sie es erneut."
            },
            unsavedChanges: "Sind Sie sicher, dass Sie abbrechen möchten?",
            unsavedWarningMessage:
                "Sind Sie sicher, dass Sie fortfahren wollen, ohne die Änderungen zu speichern?",
            video: "Video",
            videoTypeLabel: "Typ",
            videoLink: "Video-Link",
            videoLinkPlaceholder: "Video-URL-Adresse",
            voiceDescription: "Kurzbeschreibung für Smart Speaker App",
            marketingMaterialTitle: "Link Name",
            marketingMaterialUrl: "Link"
        },
        filters: {
            all: "Alle"
        },
        legalEntity: "Kammer",
        supplierEntity: "Bildungsträger",
        offerStatus: "Angebotsstatus",
        offerStatuses: {
            all: "Alle",
            approved: "Aktiv",
            draft: "Inaktiv"
        },
        search: "Suche...",
        tableHeaders: {
            classification: "Klassifizierung",
            offerStatus: "Angebotsstatus",
            title: "Titel"
        },
        openSnackbar: {
            save: "Anpassungen wurden erfolgreich gespeichert!",
            delete: "Angebot wurde erfolgreich gelöscht"
        }
    },
    offersClassification: {
        allOffers: "Alle Angebote",
        back: "Zurück",
        bulkClassification: {
            subtitle:
                "Sind Sie sicher, dass Sie alle markierten Angebote klassifizieren möchten? Bitte bestätigen Sie unten.",
            title: "Mehrfach-Klassifizierung"
        },
        classification: {
            archived: {
                description:
                    'Archivierte Angebote können im Tab „Archiviert" gefunden werden.',
                label: "Archiv"
            },
            nationwide: {
                description:
                    'Nach der Klassifizierung eines Angebots als "Nationaler Zertifikatslehrgang" muss die finale Freigabe im Tab "fertig zur Freigabe" gegeben werden.',
                label: "Nationaler Zertifikatslehrgang"
            },
            regional: {
                description:
                    'Bei der Klassifizierung eines Angebots als „Regionaler Zertifikatslehrgang" ist eine Zusammenführung und Freigabe im Tab „Fertig zur Freigabe" erforderlich.',
                label: "Regionaler Zertifikatslehrgang"
            },
            unclear: {
                description:
                    'Bei der Einstufung eines Angebots als "Unklar" ist eine weitere Prüfung im Tab "Klärung Zert. Lehrgänge" erforderlich.',
                label: "Unklar"
            }
        },
        classify: "Klassifizieren",
        close: "Schließen",
        confirm: "Bestätigen",
        error: {
            subtitle:
                "Es scheint ein technischer Fehler bei Ihrer Eingabe vorzuliegen. Bitte versuchen Sie es erneut.",
            title: "Ups, da scheint ein Fehler vorzuliegen!"
        },
        externalLink: "Info-Seite Kammer",
        filters: {
            all: "Alle"
        },
        merge: "Freigeben",
        offerSuggestions: "Angebotsvorschläge",
        open: "Öffnen",
        selectOfferToMerge: "Angebot zum Zusammenführen auswählen",
        submit: "Einreichen",
        successBulkClassification:
            "Ausgewählte Angebote wurden erfolgreich klassifiziert!",
        successMerge: {
            text1: "Die Freigabe für",
            text2: "ist erfolgreich gewesen."
        },
        tableHeaders: {
            classification: "Klassifizierung",
            supplier: "Bildungs-Träger",
            title: "Titel"
        },
        tabs: {
            archived: "Archiviert",
            pending: "Neuimporte",
            readyRelease: "Fertig zur Freigabe",
            unclear: "Klärung Zert. Lehrgänge"
        },
        tryAgain: "Erneut versuchen"
    },
    offersKeywordsTagging: {
        deleteKeyword: "Stichwort Löschen",
        deleteKeywordDescription:
            "Sie sind dabei, das Stichwort zu löschen. Sind Sie sicher, dass Sie fortfahren möchten? Wenn Sie dieses Stichwort löschen, wird es nicht mehr mit Angeboten verknüpft sein.",
        deleteKeywordsNote:
            "Wenn Sie ein Stichwort löschen, wird das Angebot nicht mehr unter diesem Stichwort gefunden. Beim Löschen aller Stichwörter ist das Angebot nicht mehr auffindbar.",
        edit: "Bearbeiten",
        editPopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            createKeyword: "Stichwort erstellen",
            duplicatedKeyword:
                "Dieses Stichwort existiert bereits. Versuchen Sie ein anderes Wort.",
            editKeyword: "Stichwort bearbeiten",
            editKeywords: "Stichwörter bearbeiten",
            saveChanges: "Änderungen speichern"
        },
        error: {
            subtitle:
                "Es scheint ein technischer Fehler bei Ihrer Eingabe vorzuliegen. Bitte versuchen Sie es erneut.",
            title: "Ups, da scheint ein Fehler vorzuliegen!"
        },
        errorDuplicatedKeyword: {
            subtitle:
                "Es scheint, dass das Stichwort, das Sie zu erstellen versuchen, bereits existiert. Bitte versuchen Sie, ein neues anzulegen.",
            title: "Vorhandenes Stichwort"
        },
        filters: {
            all: "Alle",
            noKeywords: "Ohne Stichwörter"
        },
        keyword: "Stichwort",
        offers: "Angebote",
        search: "Suche...",
        tableHeaders: {
            classification: "Klassifizierung",
            keywords: "Stichwörter",
            offersTitle: "Angebote Titel",
            offerTitle: "Angebot Titel"
        },
        tabs: {
            keywords: "Stichwörter",
            offers: "Angebote"
        },
        taggegOffers: "Getaggte Angebote",
        tryAgain: "Erneut versuchen"
    },
    offersUpload: {
        error: {
            subtitle:
                "Fehler. Leider gab es ein Problem beim Upload. Unser Team wird das prüfen und sich bei Dir melden um den Fehler zu beheben.",
            title: "Upload fehlgeschlagen"
        },
        file: "Datei",
        processing: {
            subtitle:
                "Ihre Datei wird gerade überprüft. Bitte haben Sie während dieses Vorgangs etwas Geduld, es kann ein paar Minuten dauern.",
            title: "Verarbeitung"
        },
        searchFile: "Datei durchsuchen",
        success: "Der Upload war erfolgreich.",
        upload: "Hochladen",
        uploadOffers: "Angebot erstellen"
    },
    badges: {
        headerText: "Die IHK-Badges sind da!",
        description:
            "Wenn du deinen Teilnehmern digitale Abzeichen vergeben möchtest, kannst du sie hier bestellen:",
        buttons: {
            orderBadges: "Badges Bestellen"
        }
    },
    profile: {
        editAccount: "Konto bearbeiten",
        logout: "Ausloggen",
        settings: "Ihr Konto",
        apiKeys: "API-Schlüssel"
    },
    resetPassword: {
        email: "E-Mail",
        error: {
            subtitle:
                "Es scheint, dass ein technisches Problem aufgetreten ist und etwas mit Ihrer Anfrage schief gelaufen ist. Bitte versuchen Sie es erneut.",
            title: "Huch! Etwas ist schief gelaufen."
        },
        passwordForgotten: "Passwort vergessen?",
        resetPassword: "Passwort zurücksetzen",
        subtitle:
            "Geben Sie unten die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist. Sie erhalten eine E-Mail mit einem Link, der Ihnen Anweisungen zum Zurücksetzen Ihres Passworts gibt.",
        success:
            "Wir haben Ihnen per E-Mail einen Link zum Zurücksetzen des Passworts gesendet. Der Link ist für 1 Stunde aktiv.",
        tryAgain: "Erneut Versuchen"
    },
    settings: {
        cancel: "Abbrechen",
        changePassword: "Passwort ändern",
        confirmPassword: "Passwort bestätigen",
        currentPassword: "Aktuelles Passwort",
        edit: "Bearbeiten",
        errorMessage: {
            passwordsDoNotMatch:
                "Hoppla! Sorry, aber es scheint, dass deine Passwörter nicht übereinstimmen! Bitte versuchen Sie es erneut.",
            weakNewPassword:
                "Oops sorry, dein Passwort ist zu schwach. Versuchen Sie, einen Großbuchstaben und / oder Zahlen einzufügen.",
            wrongPassword:
                "Oops sorry, dein altes Passwort scheint falsch zusein. Bitte versuchen Sie es erneut."
        },
        firstName: "Vorname",
        lastName: "Nachname",
        logoTitle: "Ziehen Sie Ihr Bild per Drag & Drop, Suchen Sie es oder ",
        logoTitleRemove: "Entfernen Sie es",
        newPassword: "Neues Passwort",
        profileInformation: "Profilinformationen",
        saveChanges: "Änderungen speichern"
    },
    validators: {
        digitsAndDot: "Nur Zahlen und . sind erlaubt",
        invalidEmail: "Ungültige E-Mail",
        invalidLink: "Inkorrektes Format des Links",
        invalidPrice: "Nur Zahlen, Punkte und Kommas sind erlaubt",
        invalidTelephone: "Ungültige Telefon",
        onlyAlphaNum: "Nur akzentfreie Buchstaben, Zahlen und -_ sind erlaubt",
        onlyDigits: "Nur Zahlen sind erlaubt",
        requiredField: "Pflichtfeld"
    },
    userManual: {
        descriptions: {
            chatInteraction: {
                1: "In der Chat-Verwaltung werden alle Nutzer-Anfragen gesammelt.",
                2: "Hier sehen Sie alle Anfragen, die Sie übernommen haben. Hier Chatten Sie dann auch mit den Nutzern, und bekommen vom System angezeigt, ob der Nutzer noch im Chat ist. Außerdem können Sie im Chat hochscrollen und die Unterhaltung des Nutzeres mit dem Bot anschauen.",
                3: 'Hier sehen Sie alle Anfragen, die noch keinem Kollegen zugeordnet sind.  Sobald Sie auf "Gespräch Einleiten" drücken, wird die Unterhaltung Ihnen zugewiesen und Sie können anfangen die Anfrage abzuarbeiten.'
            },
            offerManagement: {
                1: {
                    caption: {
                        1: {
                            description:
                                "Mit einem Klick auf Ihr Profilbild, auf Ihren Benutzernamen oder auf das Zahnradsymbol öffnen Sie das Benutzermenü mit der Möglichkeit, Ihr Konto zu bearbeiten und Sich von der Anwendung abzumelden.",
                            title: "Kontoeinstellungen"
                        },
                        2: {
                            description:
                                "Hier können Sie nach Angeboten suchen.",
                            title: "Suche"
                        },
                        3: {
                            description:
                                "Durch einen Klick auf das + Symbol erstellen Sie ein neues leeres Angebot.",
                            title: "Neues Angebot erstellen"
                        },
                        4: {
                            description:
                                "Die Liste der verfügbaren Angebote in alphabetischer Reihenfolge. Bitte beachten Sie, dass die Sortierung auf Groß- und Kleinschreibung achtet. So wird z. B. der IT-Organisationsentwickler (IHK) vor dem Ideenmanager (IHK) aufgeführt.",
                            title: "Angebote"
                        }
                    },
                    description:
                        "Auf der Startseite der Angebotsverwaltung sehen Sie."
                },
                2: {
                    description:
                        "In der Angebotsverwaltung können Sie über die Stichwortzuordnung einstellen, unter welchen Stichwörtern ein Angebot in ISA gefunden werden soll.",
                    section: {
                        1: {
                            description:
                                "Sie können jederzeit neue Stichwörter zu einem Angebot hinzufügen, oder unpassende Stichwörter löschen. Wichtig zu beachten ist, dass Angebote, die gar keine Stichwörter haben, nicht in ISA gefunden werden."
                        },
                        2: {
                            description:
                                "Andersherum können Sie auch sehen, welche Angebote unter einem bestimmten Stichwort gefunden werden."
                        },
                        3: {
                            description:
                                "Sie können jederzeit bearbeiten, welche Angebote unter einem bestimmten Stichwort gefunden werden. Sie können ein Stichwort auch komplett löschen."
                        }
                    }
                },
                3: {
                    description:
                        "Durch ein Klick auf das + Symbol öffnen Sie die Erfassungsmaske für ein neues Angebot:",
                    section: {
                        1: {
                            description:
                                "Die Inhalt der Felder <b>Titel</b> und <b>Kurzbeschreibung</b> erscheinen im Frontend zum einen in der Einzelansicht des Angebotes sowie auf den Kacheln auf der Startseite und am Fuß jedes Einzelangebotes und sind Pflichtfelder. Hat das Angebot keine Kurzbeschreibung, muss daher ein Leerzeichen erfasst werden.",
                            title: "Titel und Kurzbeschreibung"
                        },
                        2: {
                            description:
                                "Die URL bezeichnet den Teil der Internetadresse hinter dem Text <b><u>https://ihk-kompetenz.plus/zertifikatslehrgang/</u></b> und darf nur aus akzentfreien Buchstaben, Zahlen sowie den Strichen _ und - bestehen. Der Inhalt sollte dem Titel des Lehrgangs entsprechen. Leider wird diese zum Zeitpunkt der Erstellung dieses Manuals noch nicht automatisch erzeugt und muss daher von Hand erfasst werden.",
                            title: "URL"
                        },
                        3: {
                            description:
                                "Im Feld Beschreibung wird der erläuternde Text zum Angebot erfasst. Ihnen stehen zur Formatierung hierbei zwei Überschriften (H1 = Titel, H2 = Untertitel), P (regulärer Text), B (Fettschreibung) sowie ein Listenabsatz zur Verfügung.<p></p>Folgende Struktur hat sich bewährt:<ul><li>Ziel der Weiterbildung</li><li>Zielgruppe</li><li>Der Nutzen des Trainings für das Unternehmen:</li><li>Der Nutzen des Trainings für den Teilnehmer:</li><li>Berufliche Chancen:</li></ul><p></p>Dabei werden diese Überschriften mit H1 formatiert. Um ein wenig Abstand zwischen einem regulären Absatz und der nachfolgenden Überschrift zu erhalten, sollte ein leerer Absatz eingefügt werden. Listenabsätze werden im Frontend mit einer hellblauen Schattierung hinterlegt.",
                            title: "Beschreibung"
                        },
                        4: {
                            description:
                                "Ordnen Sie dem Angebot Stichwörter zu, unter denen ISA-Nutzer das Angebot auf der Webseite finden sollen. Wichtig zu beachten ist, dass Angebote ohne Stichwörter nicht von Nutzern gefunden werden können.",
                            title: "Stichwortzuordnung"
                        },
                        5: {
                            description:
                                "Wenn Sie ein Angebot erstellen, müssen Sie es klassifizieren, damit es korrekt im System eingeordnet wird. Die Angebote werden sofort im System freigegeben und sind in der Angebotsübersicht zu finden.",
                            title: "Klassifizieren"
                        },
                        6: {
                            description:
                                'Der <b>Titel</b> wird im Frontend neben einem Flyer-Symbol angezeigt und sollte den Benutzer darüber informieren, was er erwarten kann, wenn er auf den Link klickt. Bewährt hat sich ein Titel nach dem Schema <b>Informationsflyer zum Zertifikatslehrgang: "Beispielangebot"</b>.<p></p>Im Feld <b>Name</b> wird der Dateiname der Datei eingegeben, auch hier dürfen nur akzentfreie Buchstaben, Zahlen sowie die Strichen _ und - verwendet werden. Auch dieses Feld wird leider nicht automatisch ausgefüllt.<p></p>Beide Felder sind Pflichtfelder und müssen ausgefüllt werden.<p></p>Danach erfolgt der Upload der Datei mit dem <b>Thumbnail des Angebotes</b> im Feld <b>Flyer-Bild</b>. Dieses sollte auch dann bereitgestellt werden, wenn es zu dem gerade erstellten Angebot keinen Flyer gibt! Das hat jedoch zur Folge, dass bei Felder <b>Titel</b> und <b>Name</b> befüllt werden <b>müssen</b>, obwohl es keine Flyer-Datei geben wird.<p></p>Zuletzt wird im Feld <b>Flyer-Präsentation</b> die PDF-Datei des Flyers hochgeladen.<p></p>Über die Schaltfläche + <b>Flyer Hinzufügen</b> können Sie weitere dem Angebot weitere Flyer anhängen.<p></p>Über das <b>Papierkorb-Symbol</b> oben rechts können die Eingaben verworfen und der Flyer gelöscht werden.',
                            title: "Flyer"
                        },
                        7: {
                            description:
                                'Der <b>Titel</b> wird im Frontend neben einem Video-Symbol angezeigt und sollte den Benutzer darüber informieren, was er erwarten kann, wenn er auf den Link klickt. Bewährt hat sich ein Titel nach dem Schema <b>Video zum Zertifikatslehrgang: "Beispielangebot"</b>.<p></p>In das Feld <b>Video-Link</b> tragen Sie die Adresse des Videos ein. Dieser stammt im der Regel von einem Videoportal wie YouTube oder Vimeo.<p></p>Über die Schaltfläche + <b>Video Hinzufügen</b> können Sie weitere dem Angebot weitere Videos anhängen, über das <b>Papierkorb- Symbol</b> oben rechts können die Eingaben verworfen und das Video gelöscht werden.',
                            title: "Video"
                        },
                        8: {
                            description:
                                "Die Einträge für die Präsentation sowie für den.",
                            title: "Präsentation"
                        },
                        9: {
                            description:
                                "Erfolgen entsprechend den Angaben zu Flyer und Podcast.",
                            title: "Chatbot"
                        },
                        10: {
                            description:
                                "Haben Sie alle Einstellungen vorgenommen, schließen Sie die Angebotserstellung mit einem Klick auf <b>ANGEBOT ERSTELLEN</b> ab.",
                            title: "Erstellen"
                        }
                    }
                },
                4: {
                    description:
                        "Im Menü Angebotszuordnung können Sie importierte Angebote klassifizieren. Nationale und Regionale Zertifikatslehrgänge gehen in den Reiter „Fertig zur Freigabe”. Unerwünschte Angebote können Sie in den Reiter „Archiv” verschieben. Angebote deren Klassifizierung zunächst unklar ist können in den Reiter „Klärung Zert. Lehrgänge” verschoben werden."
                },
                5: {
                    description:
                        "Auf der Startseite der Angebotsverwaltung gelangen Sie durch einen Klick auf das Angebot oder das Feld <b>DETAILS</b> am Ende jeder Zeile zu den Angebotsdetails.",
                    section: {
                        1: {
                            description:
                                "Über die Schaltfläche <b>(1)</b> gelangen Sie zurück zur Übersicht, mit der Schaltfläche <b>(2)</b> können Sie das Angebot bearbeiten bzw. löschen <b>(3)</b>.<p></p>Über den Schieberegler <b>Allgemeine Informationen (4) / Veranstaltungen (5)</b> können Sie zwischen den Angebotsdetails und den Veranstaltungsterminen hin und her schalten."
                        }
                    }
                },
                6: {
                    description:
                        "Wenn Sie Ihr Angebot bearbeiten, gelangen Sie auf die gleiche Eingabemaske wie bei der Erstellung eines neuen Angebotes. Nehmen Sie die gewünschten Änderungen vor und sichern Sie diese mit einem Klick auf <b>ÄNDERUNGEN SPEICHERN.</b>"
                },
                7: {
                    section: {
                        1: {
                            description:
                                "Das Feld <b>Zugehöriges Seminar</b> ist bereits vorausgefüllt und kann nicht verändert werden.<p></p>Tragen Sie in das Feld <b>Preis</b> die Kosten für die Veranstaltung ein. Bitte beachten Sie, dass hier der Punkt als Dezimaltrennzeichen verwendet werden muss, z. B. 999.50.<p></p>Bei <b>Ereignistyp</b> wählen Sie aus den Möglichkeiten <b>Präsenz</b>, <b>Online</b> und <b>Blended</b> aus.<p></p>Unter <b>Externe ID</b> tragen Sie die Kennziffer oder Nummer der Veranstaltung ein.",
                            title: "Allgemeine Angaben"
                        },
                        2: {
                            description:
                                "Hinter dem Feld <b>Standort</b> verbirg sich ein Dropdown-Feld mit der Auswahl aller bisher angelegter Standorte. Bitte wählen Sie hier den gewünschten Standort aus.<p></p>Wenn Ihr Standort noch nicht in der Liste aufgeführt ist, können Sie durch das Ausfüllen der Felder einen neuen Standort anlegen. Bitte beachten Sie auch hier bei der Angabe der Längen- und Breitengrade, dass der Punkt als Dezimaltrennzeichen genutzt werden muss.<p></p>Kennen Sie die Angaben der Längen- und Breitengrade nicht, können Sie diese einfach über Google Maps ermitteln. Rufen Sie dazu maps.google.com auf und geben Sie die gesuchte Adresse in das Suchfeld ein. Der gefundene Ort wird in der Karte zentriert und mit einem Marker markiert. Klicken Sie mit der rechten Maustaste auf den Marker und wählen aus dem Kontextmenü den Eintrag <b>Was ist hier?</b> aus. Nun werden die gesuchten Informationen am unteren Rand der Karte angezeigt.",
                            title: "Standort"
                        },
                        3: {
                            description:
                                "Durch einen Klick auf die + <b>Module</b> Schaltfläche können Sie Ihrem Kurs bei Bedarf Module hinzufügen.<p></p>Füllen Sie die Angaben <b>Modul Nr.</b> und <b>Name</b> entsprechend Ihres Angebotes aus. Bei <b>Einheit der Dauer</b> können Sie aus <b>Stunden</b>, <b>Tag</b>, <b>Tage</b>, <b>Wochen</b> und <b>Monate</b> wählen. Geben Sie im Feld <b>Wert der Dauer</b> die entsprechende Anzahl ein.<p></p>Durch einen Klick auf <b>StartDatum</b> bzw. <b>EndDatum</b> können Sie über einen eingebauten Kalender den Start bzw. das Ende des Moduls festlegen.<p></p>Über + <b>Module Hinzufügen</b> erweitern Sie Ihren Kurs mit weiteren Modulen, über das <b>Papierkorbsymbol</b> wird das aktuelle Modul gelöscht.",
                            title: "Module"
                        },
                        4: {
                            description:
                                "Durch einen Klick auf <b>StartDatum</b> bzw. <b>EndDatum</b> können Sie über einen eingebauten Kalender den Start bzw. das Ende der gesamten Veranstaltung festlegen.",
                            title: "Datum"
                        },
                        5: {
                            description:
                                "Bei <b>Einheit der Dauer</b> können Sie aus <b>Stunden</b>, <b>Tag</b>, <b>Tage</b>, <b>Wochen</b> und <b>Monate</b> wählen. Geben Sie im Feld <b>Wert der Dauer</b> die entsprechende Anzahl ein.",
                            title: "Dauer"
                        },
                        6: {
                            description:
                                "Wählen Sie aus dem Dropdown Feld <b>IHK-Name</b> die gewünschte Kammer aus. Das Feld <b>IHK-Link</b> wird daraufhin automatisch befüllt.<p></p>Eine neue Kammer können Sie durch einfaches Ausfüllen beider Felder hinzufügen.",
                            title: "IHK-Entität"
                        },
                        7: {
                            descriptions: {
                                0: "Über die Schaltfläche + <b>Dozent hinzufügen</b> können Sie einen Dozenten hinzufügen. Es öffnet sich daraufhin die entsprechende Eingabemaske:",
                                1: "In das Feld <b>Name</b> geben Sie den Namen des Dozenten bzw. der Dozentin ein.<p></p>Über + <b>Dozent Hinzufügen</b> erweitern Sie Ihren Kurs um weitere Dozenten, über das <b>Papierkorbsymbol</b> wird der aktuelle Eintrag gelöscht. Bitte beachten Sie, dass das Feld <b>Name</b> ein <b>Pflichtfeld</b> ist, daher müssen Sie auch einen versehentlich erstellten Dozenten löschen, auch wenn das Feld Name leer ist."
                            },
                            title: "Dozent"
                        },
                        8: {
                            descriptions: {
                                0: "Über die Schaltfläche + <b>Kontakt hinzufügen</b> können Sie einen Kontakt hinzufügen. Es öffnet sich daraufhin die entsprechende Eingabemaske:",
                                1: "Über das Dropdown Feld <b>Name</b> sehen Sie alle bereits erfassten Kontakte zu der weiter oben ausgewählten <b>IHK-Entität</b>. Wählen Sie einen der Namen aus, werden die übrigen Felder automatisch ausgefüllt.<p></p>Einen neuen Kontakt erfassen Sie einfach, in dem Sie die Felder entsprechend ausfüllen. Die Felder <b>Telefon (Rufnummer)</b> sowie <b>E-Mail</b> werden während der Eingabe auf Plausibilität überprüft.<p></p>In das Feld <b>Bild-Link</b> wird die URL des Bildes einkopiert.<p></p>In den meisten Fällen erhält man den Link zum Bild, indem man mit der rechten Maustaste auf das Bild klickt. Der benötigte Eintrag heißt von Browser zu Browser unterschiedlich. Bei Google Chrome z. B. lautet er Bildadresse kopieren oder Copy Imagelocation.<p></p>Auf einigen Websites ist zum Schutz des Bildes ein transparenter Bereich vorgelegt. Hier müssen die Entwicklertools mit der Taste F12 aufgerufen werden. In der Symbolleiste der Tools klickt man ganz links einen Pfeil mit einem Rechteck und dann auf das gewünschte Bild.<p></p>Im Inspektor sieht man dann einen Ausschnitt des Codes. Hier wird der transparente „Schutz“ blau hinterlegt, die gesucht Bildadresse befindet sich direkt darüber. Diesen Eintrag <b><u>https://www.ihk24.de/v…</u></b> kann man mit der rechten Maustaste anklicken und in die Zwischenablage kopieren.",
                                2: "Mit einem Klick auf die Schaltfläche <b>EREIGNIS ERSTELLEN</b> schließen Sie Ihre Eingaben ab."
                            },
                            title: "Kontakt"
                        }
                    }
                },
                8: {
                    section: {
                        1: {
                            description:
                                "Klicken Sie in der Übersicht der Veranstaltungen zu einem Angebot auf einen <b>Eintrag</b> oder der Schaltfläche <b>DETAILS</b> rechts daneben, gelangen Sie zur Detailansicht des Angebotes.<p></p>Über den Pfeil nach links <b>(1)</b> gelangen Sie zurück zur Übersicht.<p></p>Mit Angebot bearbeiten <b>(2)</b> öffnet sich der Bearbeiten-Dialog. Dieser entspricht dem Formular zur Erstellung von Angeboten.<p></p>Mit Angebot löschen <b>(3)</b> wird das Angebot entfernt."
                        }
                    }
                }
            },
            userManagement: {
                1: "Hier werden alle Nutzer angezeigt und ihr zugehöriger Status.",
                2: "Hier können die Nutzer-Informationen bearbeitet werden und der Nutzer gelöscht werden.",
                3: "In diese Maske können neue Nutzer erstellt werden. Es ist nicht möglich Nutzer mehere Nutzer mit der selben E-Mail Adresse zu erstellen."
            }
        },
        subtitle:
            "Ein Leitfaden, der Ihnen den Einstieg in die Backoffice-Verwaltung erleichtert!",
        subTopics: {
            create: "Erstellen",
            createEvents: "Veranstaltungen erstellen",
            createNewOffer: "Neues Angebot erstellen",
            edit: "Bearbeiten",
            editOffer: "Angebot bearbeiten",
            editOrDeleteEvents: "Veranstaltungen bearbeiten oder löschen",
            keywordTagging: "Stichwortzuordnung",
            offerClassification: "Angebotszuordnung",
            offerDetails: "Angebotsdetails",
            ongoingConversations: "Gespräche",
            overview: "Übersicht",
            pendingConversations: "Gesprächsanfrage",
            titleAndShortDescription: "Titel und Kurzbeschreibung"
        }
    },
    users: {
        active: "Aktiv",
        editPopup: {
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            createUser: "Benutzer anlegen",
            deleteUser: "Benutzer löschen",
            deleteUserTitle: "Benutzer löschen",
            deleteUserMessage:
                "Sind Sie sicher, dass Sie die Daten vollständig löschen wollen? Der Benutzer wird aus der Datenbank entfernt. Nach dem Bestätigen sind diese Benutzerinformationen nicht mehr abrufbar.",
            editUser: "Benutzer bearbeiten",
            email: "E-Mail",
            entity: "Kammer",
            cooperationpartner: "Kooperationspartner",
            firstName: "Vorname",
            lastName: "Nachname",
            missingRole: "Bitte wählen Sie eine Art der Benutzerberechtigung",
            password: "Passwort",
            role: "Berechtigungsart",
            saveChanges: "Änderungen speichern",
            status: "Status",
            unsavedChanges: "Ungespeicherte Änderungen",
            unsavedWarningMessage:
                "Sind Sie sicher, dass die Fortfahren wollen, ohne die Änderungen zu speichern?"
        },
        errors: {
            default:
                "Ups, es scheint, als wäre etwas schief gelaufen. Bitte versuchen Sie es erneut!",
            emailAlreadyRegistered:
                "Die angegebene E-Mail wird bereits in einem anderen Konto verwendet. Bitte überprüfen Sie dies und versuchen Sie es erneut.",
            user: "Benutzer",
            userCreated: "wurde erfolgreich erstellt!"
        },
        filters: {
            all: "Alle"
        },
        inactive: "Inaktiv",
        passwordLengthError:
            "Sie haben ein zu schwaches Passwort eingegeben. Bitte achten Sie darauf, dass es mehr als 6 Zeichen enthält. Wie empfehlen außerdem, auch Zahlen und Symbole zu verwenden.",
        roles: {
            ccadmin: "Call-Center Administration",
            ccagent: "Call-Center Vertreter",
            cooppartneradmin: "Kooperationspartner Admin",
            cooppartneragent: "Kooperationspartner Mitarbeiter",
            dihkadmin: "Administration DIHK-Bildungs-gGmbH",
            dihkemployee: "DIHK-Bildungs-gGmbH Mitarbeiter",
            ihkadmin: "IHK Projektverantwortliche/-r",
            ihkagent: "IHK Mitarbeiter",
            superuser: "Superuser"
        },
        search: "Suche...",
        tableHeaders: {
            email: "E-Mail",
            firstName: "Vorname",
            role: "Berechtigungsart",
            status: "Status",
            surname: "Nachname",
            user: "Benutzer"
        }
    }
};
