/* eslint-disable no-unused-vars */
// Modules
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { push, replace } from "connected-react-router";

//Actions
import { getToken } from "./Badges.ducks";
import { connect } from "react-redux";
// Components
import { MaterialFabButton } from "../../../components";
// Translation
import translation from "../../../config/translation";
// Styles
import styles from "./Badges.css";

const Badges = ({ profileData, userId, userEmail, getToken }) => {
    const userRole = get(profileData, "role.key", undefined);
    const entityId = get(profileData, "entity_id", undefined);

    const queryParams = new URLSearchParams(window.location.search);
    const shouldOpenApiKeysModal =
        queryParams.get("openApiKeysModal") === "true";

    useEffect(() => {
        getToken &&
            getToken(
                userId,
                userEmail,
                userRole,
                entityId,
                shouldOpenApiKeysModal
            );
    }, []);

    const handleOpenOrderBadgeLink = () => {
        const orderBadgesLink = process.env.REACT_APP_ORDER_BADGE_PDF_LINK;
        window.open(`${orderBadgesLink}}`, "_blank");
    };

    return (
        <div style={styles.container}>
            <div style={styles.headerTitle}>
                {translation.navigationMenu.badges}
            </div>
            <div style={styles.innerContainer}>
                <img
                    alt={"wip"}
                    style={styles.image}
                    src={require("../../../images/wip.png").default}
                />
                <div style={styles.title}>{translation.badges.headerText}</div>
                <div style={styles.text}>{translation.badges.description}</div>
                <MaterialFabButton
                    onClick={handleOpenOrderBadgeLink}
                    variant="extended"
                >
                    <span className="icon-plus" style={styles.addButtonIcon} />
                    {translation.badges.buttons.orderBadges}
                </MaterialFabButton>
            </div>
        </div>
    );
};

Badges.propTypes = {
    getToken: PropTypes.func.isRequired,
    token: PropTypes.string,
    userId: PropTypes.string,
    userEmail: PropTypes.string,
    profileData: PropTypes.object
};

const mapStateToProps = state => {
    return {
        token: state.badges.token,
        userId: state.firebase.auth.uid,
        userEmail: state.firebase.auth.email,
        profileData: state.profileData
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getToken
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
